<template>
    <div>
        <h2>{{ $t('cem.coordenadas') }} - Pokémon 100%</h2>
        <small class="small">by Marcelo</small>
        <Loading :active="loading" />
        <div class="nota">
            <i class="fa fa-info-circle"></i>
            {{ $t('cem.nota') }}
        </div>
        <div class="list-group">
            <div class="list-group-item" v-for="(m, index) in mensagens" :key="index">
                <div class="coord-content">
                    <div class="coord-texts">
                        <div class="city">{{ m.datacad | corrigeData }}</div>
                        <div class="coord-text" :style="
                          select.filter(i => i === m.key).length
                            ? 'text-decoration: line-through;'
                            : ''
                        ">
                            {{ m.coords }}
                        </div>
                    </div>
                    <div class="coord-btn">
                        <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="m.copy">
                            <template v-slot:front>
                                <div class="btn" @click="getCopy(m)">
                                    {{ $t('cem.copiar') }}
                                </div>
                            </template>
                            <template v-slot:back>
                                <div class="btn" @click="getCopy(m)">=)</div>
                            </template>
                        </vue-flip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueFlip from 'vue-flip'
import moment from 'moment'
import Loading from '../components/Loading'
import { onValue } from "firebase/database"
import { getAnalytics, logEvent } from 'firebase/analytics'

import CoordsDataService from '../services/services'

export default {
    components: { VueFlip, Loading },

    data: () => ({
        mensagens: [],
        currentCoords: null,
        currentIndex: -1,
        loading: false,
        select: []
    }),
    filters: {
        corrigeData(x) {
            return moment(x, "YYYY-MM-DD HH:mm:ss").fromNow()
        }
    },
    methods: {
        onDataChange(items) {
            let _mensagens = []
            items.forEach(item => {
                let key = item[0]
                let data = item[1]
                _mensagens.push({
                    key: key,
                    coords: data.coords,
                    datacad: data.datacad,
                    copy: false,
                    user: data.user
                })
            })
            _mensagens = _mensagens.filter(i => i.user === 'Pókemon100')
            _mensagens = _mensagens.reverse()
            window._mensagens = _mensagens
            _mensagens = _mensagens.length ? _mensagens.slice(0, 200) : []
            this.loading = false
            this.mensagens = _mensagens
        },

        refreshList() {
            this.currentCoords = null
            this.currentIndex = -1
        },
        async getCopy(coord) {
            if (!coord.copy) {
                const results = await coord.coords.matchAll(
                    // eslint-disable-next-line no-useless-escape
                    /(-?\d+(?![:|\/]).\d+, ?-?\d+(?![:|\/]).\d+)(:blank:)?(.+?(\d+\w+)?(\d+ ?)(\w{3}))?/g
                )
                const x = Array.from(results).map(i => {
                    return i[1]
                })
                //console.log(results)
                //coord.coords
                this.$copyText(x).then(
                    () => null,
                    () => alert('Erro')
                )
                const index = this.select.indexOf(coord.key)
                if (index === -1) {
                    this.select.push(coord.key)
                }
            } else {
                const index = this.select.indexOf(coord.key)
                console.log(index)
                if (index > -1) {
                    this.select.splice(index, 1)
                }
            }
            //coord.copy = !coord.copy
        }
    },
    async mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Pkm100'
        })
        switch (this.$i18n.locale) {
            case 'pt-br':
                moment.locale('pt-br')
                break
            case 'es-es':
                moment.locale('es')
                break
            case 'en-us':
                moment.locale('en')
                break
        }
        this.loading = true
        const x = await CoordsDataService.getAll()
        onValue(x, (snapshot) => {
            const data = snapshot.val()
            this.onDataChange(Object.entries(data))
        })
    },
    beforeDestroy() {
        CoordsDataService.off()
    }
}
</script>

<style lang="scss" scoped>
h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0 0;
}

.small {
    display: flex;
    justify-content: start;
    margin-bottom: 10px;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.btn-content {
    margin: 0 auto;
}

.coord-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .coord-texts {
        flex: 2;
        display: flex;
        align-items: start;
        flex-direction: column;

        .city {
            color: #2c3e50;
            font-size: 10px;
            text-align: center;
            font-weight: bold;
            width: 85px;
        }

        .coord-text {
            color: #2c3e50;
            font-size: 14px;
        }
    }

    .coord-btn {
        flex: 1;

        a {
            display: flex;
            width: 80px;
            color: #fff;
            background-color: #673ab7;
            text-decoration: none;
            padding: 10px 15px;
        }
    }
}
</style>

<style lang="scss">
.missao {
    margin-top: 10px;
}

ul.home {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    line-height: 30px;
}

li a {
    color: #2c3e50;
    text-decoration: none;
}

.coord {
    width: 200px;
}

textarea {
    width: 100%;
    margin: 10px auto;
    border: 2px solid #673bb7;
    padding: 10px;
    box-sizing: border-box;
}

table {
    margin: 0 auto;
    width: 100%;
}

td {
    text-align: left;
}

td:nth-child(2) {
    width: 70px;
}

hr {
    border-bottom: 1px dashed #ccc;
    border-style: dashed;
    width: 300px;
}

.list-corpo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    font-size: 18px;
    padding: 5px;
    margin: 0 5px;
}

.whats {
    margin-top: 4px;
}

.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

.btn-content {
    margin: 0 auto;
}

.btn-container {
    flex: 1;
    margin: 0 auto 15px auto;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.text-tempo {
    background-color: #673bb7;
    color: #fff;
    font-size: 14px;
    padding: 4px;
}

.btn-down {
    background: #673bb7;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-yellow {
    background-color: #ffc107;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-container {
    flex: 1;
    margin: 0 3px 15px 3px;
}

.list {
    padding: 5px;
    box-sizing: border-box;
    text-align: initial;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    flex: 1;
}

.list small {
    font-size: 12px;
    font-weight: normal;
}

.date {
    font-size: 10px !important;
}

.list.active {
    background: rgb(103 59 183 / 21%);
}

.list-content {
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
}

.list-content .btn-down {
    width: 120px;
    height: 35px;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
}

.vue-ads-leading-loose {
    display: none;
}

.vue-ads-justify-end {
    justify-content: flex-start;
}

.vue-ads-bg-teal-500 {
    background-color: #673bb7;
}

.vue-ads-flex {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 10px 0;
}

.vue-ads-flex-grow {
    flex-grow: 0;
    flex: 1;
    justify-content: space-between;
}

.yellow {
    background-color: #ffc107 !important;
}

.logo {
    background: #673bb7;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: #fff;
    margin-left: 15px;
}

.logo .c {
    position: relative;
    top: -12px;
    left: -4px;
}

.nota {
    font-size: 12px;
    color: #2c3e50;
    border: 1px solid #673bb7;
    background: #ffc107;
    //background-color: #66ff07;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    /* position: sticky;
  top: 0; */
    height: 48px;
    z-index: 2;
    margin-bottom: 10px;
}

.notainsta {
    font-size: 14px;
    color: #fff;
    border: 1px solid #673bb7;
    background: #673bb7;
    //background-color: #66ff07;
    padding: 5px;
    border-radius: 5px;
    z-index: 2;
    margin-bottom: 10px;

    a {
        color: #fff;
    }
}
</style>

<style lang="scss" scoped>
.btn-copy {
    color: #fff;
    background: #673ab7;
    width: 110px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
}

.btn-velocity {
    color: #fff;
    background: #673ab7;
    width: 130px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.cards {
    border-radius: 5px;
    font-size: 14px;
}
</style>